import React from 'react';
import './App.scss';
import logo from './logo.png';
import menu from './menu.pdf';
import poke from './poke.pdf';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Gallery from 'react-photo-gallery';
import Fade from 'react-reveal/Fade';
// import ImageGallery from 'react-image-gallery';


const images = [
	{
		src: ' /images/photo1.jpg',
		width: 10,
		height: 17,
	},
	{
		src: ' /images/photo2.jpg',
		width: 17,
		height: 10,
	},
	{
		src: ' /images/photo3.jpg',
		width: 1,
		height: 1,
	},
	{
		src: ' /images/photo4.jpg',
		width: 3,
		height: 4,
	},
	{
		src: ' /images/photo5.jpg',
		width: 1,
		height: 1,
	},
	{
		src: ' /images/photo6.jpg',
		width: 5,
		height: 4,
	},
	{
		src: ' /images/photo7.jpg',
		width: 5,
		height: 4,
	},
	{
		src: ' /images/photo8.jpg',
		width: 3,
		height: 4,
	},
	{
		src: ' /images/photo9.jpg',
		width: 11,
		height: 10,

	},
];
// const images = [
//     {
//         original: ' /images/photo1.jpg',
//     },
//     {
//         original: ' /images/photo2.jpg',
//     },
//     {
//         original: ' /images/photo3.jpg',
//     },
//     {
//         original: ' /images/photo4.jpg',
//     },
//     {
//         original: ' /images/photo5.jpg',
//     },
//     {
//         original: ' /images/photo6.jpg',
//     },
//     {
//         original: ' /images/photo7.jpg',
//     },
//     {
//         original: ' /images/photo8.jpg',
//     },
//     {
//         original: ' /images/photo9.jpg',

//     },
// ];

const Site = () => {

	return (
		<div className="Site">
			<Fade cascade>
				<div className="section header">
					<div>
						<img src={logo} alt={'Sakura Ponte Tresa logo'} />
					</div>
					<p>
						Ristorante di cucina giapponese alla carta e all you can eat
					</p>
				</div>
			</Fade>
			<div className="section gallery">
				<Gallery photos={images} />
				{/* <ImageGallery items={images} showThumbnails={false} /> */}

			</div>
			<div className="section footer">
				<div className="firstRow">
					<a href={menu} download className="iconItem">
						<img src="/icons/menu-icon.png" alt="Menu Icon" />
						Il nostro menu
					</a>
					<br />
					<a href={poke} download className="iconItem">
						<img src="/icons/poke-icon.png" alt="Menu Poke Icon" />
						Le nostre poke
					</a>
				</div>
				<div className="secondRow">
					<div className="maps">
						<iframe title="map" className="map" id="gmap_canvas" src="https://maps.google.com/maps?q=sakura%20ponte%20tresa&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
						{/* <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=sakura%20ponte%20tresa&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe> */}
						{/* <Maps /> */}
					</div>
					<div>
						<p>
							<a href="https://www.google.com/maps?uact=5&q=sakura+ponte+tresa&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjowZrP15XmAhVPzqYKHeGODY4Q_AUoAnoECAwQBA" rel="noopener noreferrer" target="_blank">
								Ristorante Sakura, <br />
								Viale della Repubblica 4 <br />
								21307 Laverna Ponte Tresa <br />
								Italia
							</a>
						</p>
						<p>
							Telefono: <a href="tel:+393778452197">+39 377 845 2197</a>
						</p>
					</div>

				</div>
			</div>
		</div>
	);

}

export default Site;
